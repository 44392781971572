<template>
	<!--我的待阅-->
	<mainBox class='waitReadHtml'>
		<div class="tabs_warp">
	  		<div class="it_tap" v-for="(it,dex) in tabsArr" :key='dex' :class="{'actived':activeTabs==it.val}" >
	  			<div  class="it_n" @click='setTabChange(it)'>{{ it.name }} <div class="xbtm"></div></div>
	  		</div>
	  	</div>	
		<TSeach :topSearch="topSearch" v-model="seachData" @initSeach="initSeach">
			<!--<template slot='btnSlot'>
				<div class="eventBtn rightBtnGroup">
					<el-button class="itBtn" type="primary" icon="el-icon-plus"  @click="tbRowAdd">{{$t("commons.add")}}</el-button>
				</div>
			</template>-->
		</TSeach>
		<div class="t-bodyWarp">
			<TTable ref="pointTable" :maxHeight='610' :border='false' :tbloading='tbloading'  :tableData="tableData" :tableTitle="tableTitle" :tbParams="tableParams">
				
				<template slot='rowIndex' slot-scope="s">
					{{s.$index+1}}
				</template>
				<template slot='operating' slot-scope="scope">
					<el-button type="text" @click.stop="handleItMes(scope.row)"><span class="xblue">详情</span></el-button>
				</template>
				
			</TTable>
		</div>
		<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
	</mainBox>
	
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TSeach from '@/components/YTable/TSeach.vue';
	import TTable from '@/components/YTable/TTable.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable,TSeach,TPagination},
		data() {
			return {
				activeTabs:'N',
				tabsArr:[//(ALL:全部，N:未读，Y:已读)
					{name:'我的待阅',val:'N'},
					{name:'我的已阅',val:'Y'},
				],
				addModel:{//新增，编辑的弹框信息
		      		open:false,
		      		editRowId:'',
		      	},
			};
		},
		created() {
			this.initTbConfig();
		},
		watch: {
			seachData: {
				deep: true, // 深度监听
				handler(val) {
					console.log('topSearch',val)
					this.initTable();
				},
			},
		},
		mounted() {},
		computed: {},
		methods: {
			initTbConfig(){
				const seachArr = [
			    	{name:'标题',type:"text",bindKeys:"redTitle",defaultValue:"",},
			    	{name:'发起人',type:"text",bindKeys:"releaseUserName",defaultValue:"",},
				]
				const tableTitle = [
					{name:'序号',prop:"index",type:'slot',slotName:"rowIndex"},
					{name:'标题',prop:"redTitle"},
					{name:'发起人',prop:"releaseUserName"},
					{name:'接收时间',prop:"releaseDateStr"},
				    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				];
				this.topSearch = seachArr;//参数需注入到minxs
				this.tableTitle = tableTitle;
			},
			setTabChange(it){
				this.activeTabs=it.val;
				for(let keys in this.seachData){
					this.seachData[keys]='';
				}
				this.tableParams.current=1;
				this.initTable();
//				this.$emit('saveWebData')
			},
			async initTable(){
				let params = {
					...this.seachData,
					isRead:this.activeTabs,
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				console.log('initTable',this.seachData,this.tableParams)
//				delete params.total;
	  			this.tbloading=true;
	  			let res = await this.ApiHttp('/workflow/read/findReadInfoPage',params);
	  			console.log('initTable',res)
	  			if(res){
	  				res.rows.map(it=>{
	  					it.releaseDateStr=this.common.getTimeMmss(it.releaseDate)
	  				})
	  				this.tableData = res.rows||[];
					this.tableParams.total = res.total;
					this.tbloading=false;
	  			}
				
			},
			async handleItMes(row){
				if(this.activeTabs=='Y'){
					//如果是已读的话 直接跳转对应详情页
					this.jumpToDetails(row);
					return;
				}
				//待办|待阅单行点击  变成已读或者已处理  type=handle || read
				let rqData={
					"isRead": "Y", 
					"redId":row.redId,
					"redUserAccount":row.redUserAccount,
				}
				let res =await this.ApiHttp(`/workflow/read/read`,rqData,'post');
				if(res){
					row.exmDetailLink=row.redDetailLink;
					this.initTable();//刷新我的待阅数据
					this.jumpToDetails(row);
				}
				
			},
			async jumpToDetails(row) {
				const { systemName, exmDetailLink,serviceCode }=row
			      if (systemName === "工单") {
			        const url = `${location.origin}${location.pathname}#/iot/orderQuery/detail?orderId=${exmDetailLink}`;
			        window.open(url, "_blank");
			      } else if (systemName === "巡检") {
			        const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/detail?inspectionJobId=${exmDetailLink}`;
			        window.open(url, "_blank");
			      }
//		      
			      else if(systemName === "审核"||systemName === "待阅"){
			      	let url = `${location.origin}${location.pathname}#/approval-process/detail?definitionId=${serviceCode}`;
					if (exmDetailLink) {
		              url += `&taskId=${exmDetailLink}`;
		            }            
					window.open(url, "_blank");
			      }
		    },
			tbRowAdd(){
				this.addModel={
		      		open:true,
		      		editRowId:'',
		      	};
			},
			
		},
	};
</script>

<style>
	.waitReadHtml{
		background: none;
	 	padding: 20px 40px;
	 	.tabs_warp{
			line-height: 48px;
			display: flex;
	        position: relative;
        	top: -20px;
			border-bottom: 1px solid #eee;
			.it_tap{
				padding:0px 10px;
				line-height: 48px;
				color:#5D687C;
				cursor: pointer;
				flex: 8;
				max-width: 80px;
				text-align: center;
		        .it_n{
		        	font-size: 14px;
		        	font-weight: bold;
		        	position: relative;
		        	.xbtm{
		        		height: 2px;
		        		width: 20px;
		        		position: absolute;
		        		bottom: -1px;
		        		left: 50%;
		        		margin-left: -10px;
		        		
		        	}
		        }
			}
			.it_tap:hover{
				color: #2a61ff !important;
			}
			.actived.it_tap{
				color: #252D3D;
				.xbtm{
					background: #1A4CEC;
				}
				
			}
		}
		.m-body{
		    padding: 20px;
			box-shadow:none;
			border-radius:10px;
		}
		.t-bodyWarp{
			.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
				border-bottom: none !important;
			}
			.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell{
				background: #FAFBFE;
			}
			tbody td.el-table__cell{
				height: 58px;
			}
			.TTable .el-table__header tr th {
			    background: #F0F4FA !important;
			    .cell{
			    	font-weight: bold;
	                color: #5D687C;
			    }
			}
			.xblue{
				color: #2a61ff;
			}
		}
		
	}
</style>